export interface Obra {
  name: string;
  description?: string;
  codigoCliente?: number;
  delegacion?: number;
  obraCode: number;
  reference: string;
  calRef: string;
  date?: any;
  responsible: number;
  status?: ObraStatus;
  company: number;
  companyDestino?: number;
  Administracion: boolean;
  SolActividad?: boolean;
  SolCapitulo?: boolean;
  SolPresupuesto?: boolean;
  presupuestos?: any[];
  tipo?: any;
  tipo_obra?: any;
}

export enum ObraStatus {
  OPEN,
}

export interface Presupuesto {
  Descripcion: string;
  Estado: string;
  Fecha: string;
  Obra: number;
  Presupuesto: number;
  Anexo: number;
  Empresa: number;
  Delegacion: number;
  Referencia: string;
  Tipo: number;
  value: any;
  label: string;
}

export interface Capitulo {
  Capitulo: string;
  Descripcion: string;
  Nivel: number;
  Partida: boolean;
  Presupuesto: number;
  codhpre: number;
  label?: string;
}
