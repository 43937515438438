import EsfirusTextInput from "@components/ui/Text-input/EsfirusTextInput";
import "./ResetPassword.scss";
import { useDispatch, useSelector } from "react-redux";
import { setresetpass, setvalidations } from "../../store/slices/user";
import lockImage from "../../assets/images/lock.png";
import EsfirusButton from "@ui/Button/EsfirusButton";
import { authService } from "@services/auth";
import { useEffect, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function ResetPassword() {
    const navigate = useNavigate();
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    };

    const user = useSelector((state) => (state as any).user);
    const { pass, passCheck } = useSelector((state) => (state as any).user.resetPass);
    const [opensnack, setOpenSnack] = useState(false);
    const [opensnackErrorEmail, setOpensnackErrorEmail] = useState(false);

    const dispatch = useDispatch();
    const checkPass = async () => {
        const { resetPass, validations } = user;
        const notPass = !resetPass.pass || resetPass.pass === '';
        const notCheckPass = !resetPass.passCheck || resetPass.passCheck === '';

        const samePass = resetPass.pass === resetPass.passCheck;
        const sort = resetPass.pass.length <= 7;
        dispatch(setvalidations({ ...validations, isEmpty: notPass || notCheckPass, isValid: samePass && !sort } as any))
    }

    useEffect(() => {
        checkPass();
    }, [passCheck, pass])

    return (
        <div className="reset-container reset-background">
            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Has cambiado tu contrasea con éxito
                </Alert>
            </Snackbar>
            <Snackbar open={opensnackErrorEmail} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Ha habido un error, vuelve a intentarlo más tarde.
                </Alert>
            </Snackbar>
            <div className='password-modal'>
                <div className='header'>
                    <img src={lockImage} className="App-logo" alt="logo" />
                    <div className="text-center">Introduce tu nueva contrasea</div>
                </div>
                <div className='body'>
                    <EsfirusTextInput className="mt-20" type="password" placeholder="Contraseña" onChange={(e: any) => {
                        dispatch(
                            setresetpass({ ...user.resetPass, pass: e.target.value })
                        );
                        checkPass();
                    }} />
                    <div className='body'>
                        <EsfirusTextInput className="mt-20" type="password" placeholder="Contraseña" onChange={(e: any) => {
                            dispatch(
                                setresetpass({ ...user.resetPass, passCheck: e.target.value })
                            );
                            checkPass();
                        }} />
                        <EsfirusButton disabled={user.validations.isEmpty || !user.validations.isValid} className="mt-20" width={290} fontSize={25} size='small'
                            onClick={async () => {
                                try {
                                    await authService.changePassword(
                                        window.location.pathname.split('/').at(-2) || '',
                                        user.resetPass.pass,
                                        window.location.pathname.split('/').at(-1) || ''
                                    );

                                    setOpenSnack(true);
                                    navigate('/Login');
                                } catch (error) {
                                    setOpensnackErrorEmail(true);
                                }
                            }}>Cambiar contraseña</EsfirusButton>
                    </div>
                    {user.validations.isValid ? <></> : <span>Las contraseñas no coinciden</span>}
                </div>
            </div>
        </div>
    );
  };

export default ResetPassword;
