import { useEffect, useState } from "react";
import Header from "@components/Header/Header";
import EsfirusResumeAsync from "@components/ui/ResumeAsync/EsfirusResumeAsync";
import PartesHeader from "./BBs/header/header";
import PartesSubHeader from "./BBs/subheader/subheader";
import EsfirusPartesTable from "./BBs/table/Table";
import { useDispatch, useSelector } from "react-redux";
import { resetLines } from "@store/slices/lines";
import { useNavigate } from "react-router-dom";
import { removeSelectedObra } from "@store/slices/selectedObra";
import { filterPartes } from "@helpers/parsers";
import usePartesGet from "@services/hooks/usePartesGet";
import { canSupervise, isEmpleado } from "@services/hooks/UserHook";
import { deleteReport } from "@store/slices/reports";
import { setSelectedEmpleadoHome } from "@store/slices/partes";
import { emptyResourceRedirect } from "@helpers/navigate";
import { EsfirusTablePropsPagination, HandleChangePaginationType } from "@services/hooks/useHandlerPaginationTable";
import { usersService } from "@services/users";

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const partes = useSelector((state) => (state as any).partes);
  const selectedDateFrom = useSelector((state) => (state as any).partes.selectedDateFrom);
  const selectedEmpresa = useSelector((state) => (state as any).partes.selectedEmpresa);
  const selectedEmpleado = useSelector((state) => (state as any).partes.selectedEmpleadoHome);

  const empresas = useSelector((state) => (state as any).empresas).list;
  const selectedDateTo = useSelector((state) => (state as any).partes.selectedDateTo);
  const configuration = useSelector((state) => (state as any).configurations.config);
  const [search, setFilterBySearch] = useState<any>("");
  const selectedTypeHour = partes.selectedTypeHour;
  const obras = useSelector((state) => (state as any).obras).list;


  const filterByType = useSelector((state) => (state as any).partes.filterByType);

  const [listPartes, setListPartes] = useState([]);
  const totalItems = partes.totalItems;
  const [pagination, setPagination] = useState<EsfirusTablePropsPagination>({ page: 0, sizePage: 50 })


  const getPartes = usePartesGet()
  const [empleadosList, setEmpleadosList] = useState<any>([]);

  useEffect(() => {
    emptyResourceRedirect({ check: obras.length === 0, path: "/partes", navigate });

    const filters = {
      dateFrom: selectedDateFrom,
      dateTo: selectedDateTo,
      search: search,
      empresa: selectedEmpresa,
      empleado: selectedEmpleado,
    }
    dispatch(getPartes(pagination.page, pagination.sizePage, filterByType, filters) as any);
    dispatch(removeSelectedObra());
    dispatch(resetLines());
  }, [configuration, pagination, filterByType, selectedDateFrom, selectedDateTo, search, selectedEmpresa, selectedEmpleado]);

  const fetchEmpleados = async () => {
    try {
      const resp = await usersService.getAll()
      const empleados = resp?.data?.data ?? []
      const empleadosWithData = empleados.map((empleado: any) => ({ ...empleado, data: JSON.parse(empleado.data) }))
      const empleadosOptions = empleadosWithData.map((empleado: any) => {
        const idEmpresaInterna = empleado.data.internal[0].empresa
        const empresaObject = empresas.find((empresa: any) => empresa.Empresa === idEmpresaInterna)

        const label = `${empleado.nombre} ${empleado.apellidos} - ${empresaObject.Nombre}`
        const value = empleado.id
        return ({
          label,
          value,
        })
      })
      setEmpleadosList(empleadosOptions)
    } catch (error) {
      setEmpleadosList([])
    }
  }

  useEffect(() => {
    const permitirSeleccionDeEmpleados = canSupervise()
    if (permitirSeleccionDeEmpleados) {
      fetchEmpleados()
    } else {
      dispatch(setSelectedEmpleadoHome(localStorage?.getItem("ef-user-id") as any))
    }
  }, [empresas]);

  useEffect(() => {
    setListPartes(partes.list);
  }, [partes.list]);

  const deleteParte = (id: any) => {
    dispatch(deleteReport(id) as any)
      .then(
        () => dispatch(getPartes(0, pagination.sizePage, filterByType) as any)
      );
  };
  const partesOrdered = () => {
    return partes?.list?.slice().sort((a: any, b: any) => b.date - a.date);
  };

  const handleChangePagination: HandleChangePaginationType = (newPaginationData) => {
    setPagination(newPaginationData)
  }

  return (
    <>
      <Header />
      <div className="center">
        <PartesHeader showResumenButton={!isEmpleado() || configuration?.report?.reportCerrarDias}></PartesHeader>
        <PartesSubHeader
          setFilterBySearch={setFilterBySearch}
          selectedEmpresa={selectedEmpresa}
          selectedDate={selectedDateFrom}
          selectedDateFrom={selectedDateFrom}
          selectedDateTo={selectedDateTo}
          selectedEmpleado={selectedEmpleado}
          empleadosList={empleadosList}
          selectedTypeHour={selectedTypeHour}
        ></PartesSubHeader>
        <EsfirusPartesTable
          partes={(listPartes as any) || []}
          empresas={empresas}
          deleteReport={deleteParte}
          pagination={pagination}
          totalItems={totalItems}
          handleChangePagination={handleChangePagination}
        ></EsfirusPartesTable>
        <EsfirusResumeAsync />
      </div>
    </>
  );
}

export default Home;
