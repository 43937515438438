import Grid from "@mui/material/Grid";
import gastoIcon from "@assets/svgs/lineasGasto.svg";
import EsfirusTextInput from "@ui/Text-input/EsfirusTextInput";
import "./LineasGasto.scss";
import EsfirusSelect from "@ui/Select/EsfirusSelect";
import { useDispatch, useSelector } from "react-redux";
import { updateLine } from "@store/slices/lines";
import { Capitulo } from "@models/obra";
import { useEffect, useState } from "react";
import { erpService } from "@services/erp";
import useDebounce from "@services/hooks/useDebounce";
import { configurationService } from "@services/configApp";

interface Gasto {
  Articulo: string;
  Ambito: number;
  Descripcion: string;
  Auxiliar: string;
  Marca: string;
  Coste: number;
  Gasto: boolean;
  Publicable: boolean;
  Referencia: string;
  Servicio: boolean;
  Tarifa: number;
}

function LineasGasto(props: any) {
  const dispatch = useDispatch();
  const lineas = useSelector((state) => (state as any).lines);
  const [showPrice, setShowPrice] = useState(false);

  const config = configurationService.getConfig();
  const selectedobra = useSelector((state) => (state as any).selectedobra).obra;
  const gastosServicios = useSelector((state) => (state as any).articulosGastosServicios).list;
  const empresas = useSelector((state) => (state as any).empresas).list;
  const cantidadDebounced = useDebounce(lineas?.list[props.index]?.amount, 500)

  useEffect(() => {
    const updateSalesPricing = async () => {
      const gastoRef = lineas.list[props.index]?.gasto
      const selectedGasto = gastosServicios.find(
        (el: Gasto) => el.Articulo === gastoRef
      )
      const salesPricingData = await fetchSalesPricingData(selectedGasto, Number(cantidadDebounced) ?? 1)

      dispatch(
        updateLine({
          ...lineas.list[props.index],
          data_sales_pricing: salesPricingData?.data_sales_pricing ?? {}
        })
      )
    }

    const goToFetchSalesPricing = selectedobra.Administracion && lineas?.list?.[props.index]?.gasto && config?.ftp_method === "endpoint"
    if (goToFetchSalesPricing) {
      updateSalesPricing()
    }
  }, [cantidadDebounced, lineas.list[props.index]?.gasto])

  const getChapterOptions = (): any[] => {
    if (!selectedobra.capitulosByPresupuesto) {
      return [];
    }

    const ret: any = selectedobra.capitulosByPresupuesto?.map((cap: Capitulo) => ({
      ...cap,
      label: cap.label,
      value: cap.Capitulo,
    }));
    return ret
  };

  const getGastos = () => {
    const empresaSelected = empresas.find((emp: any) => emp.Empresa === selectedobra.company)
    const gastos: Gasto[] = gastosServicios.filter((el: Gasto) => el.Gasto && el.Ambito == empresaSelected.Ambito);
    return gastos.map((el: Gasto) => ({
      value: el.Articulo,
      label: el.Articulo,
    }));
  };


  useEffect(() => {
    const asyncFunc = async () => {
      const selectedGasto = gastosServicios.find(
        (el: Gasto) => el.Articulo === lineas.list[props.index]?.gasto
      );

      if (!selectedGasto) return

      const costeObra = selectedGasto?.Coste
      const costeAdmin = config?.ftp_method === "endpoint" ? await fetchSalesPricingData(selectedGasto, lineas.list[props.index]?.amount ?? 1) : 0

      if ((selectedobra?.Administracion && !costeAdmin) || (!selectedobra?.Administracion && !costeObra)) {
        setShowPrice(true)
      } else {
        setShowPrice(false)
      }
    }

    asyncFunc()
  }, [])

  const fetchSalesPricingData = async (servicio: any, quantity: any) => {
    const salesResponse = await erpService.getSalesPricing(servicio, selectedobra, quantity)
    const sales = salesResponse?.data?.data ?? {}
    return sales
  }

  return (
    <Grid
      className="line-services-container"
      container
      spacing={1}
      alignItems={"center"}
    >
      <Grid
        className="flex-row"
        item
        xs={12}
        md={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <img className="logo mr-10" src={gastoIcon} alt="service" />
        <h4>GS</h4>
      </Grid>
      <Grid item xs={12} md={10} container spacing={2}>
        {!selectedobra.Administracion && (selectedobra.SolCapitulo &&
          selectedobra.capitulosByPresupuesto.length !== 0) && (
            <Grid item xs={12} md={4}>
              <label>Capítulo</label>
              <EsfirusSelect
                disabled={!props.editable}
                optionsStyle={{ textAlign: "left", fontWeight: "bold" }}
                options={getChapterOptions()}
                value={lineas?.list[props.index]?.chapter?.Capitulo}
                change={(e: any) => {
                  const selectedChapter =
                    selectedobra.capitulosByPresupuesto.find(
                      (el: Capitulo) => el.Capitulo === e
                    );

                  dispatch(
                    updateLine({
                      ...lineas.list[props.index],
                      chapter: selectedChapter,
                    })
                  );
                }}
                placeholder={"Capítulo"}
                modified={!!lineas.list[props.index]?.validationModifications?.datos?.chapter}
              ></EsfirusSelect>
            </Grid>
          )}

        <Grid item xs={12} md={4}>
          <label>Gasto</label>
          <EsfirusSelect
            options={getGastos()}
            disabled={!props.editable}
            value={lineas.list[props.index]?.gasto}
            change={(ref: string) => {
              const selectedGasto = gastosServicios.find(
                (el: Gasto) => el.Articulo === ref
              );

              if (!selectedGasto.Coste) {
                setShowPrice(true)
              } else {
                setShowPrice(false)
              }

              const precio = selectedGasto.Coste ?? 0

              dispatch(
                updateLine({
                  ...lineas.list[props.index],
                  gasto: selectedGasto.Articulo,
                  description: selectedGasto.Descripcion,
                  amount: 1,
                  tarifa: selectedGasto.Tarifa,
                  totalPrice: precio ?? 0,
                  precio: precio ?? 0,
                })
              );
            }}
            modified={!!lineas.list[props.index]?.validationModifications?.datos?.gasto}
            required={true}
          ></EsfirusSelect>
        </Grid>
        <Grid item xs={12} md={4}>
          <label>Descripción</label>
          <EsfirusTextInput
            fullWidth
            disabled={!props.editable}
            onChange={(e: any) => {
              dispatch(
                updateLine({
                  ...lineas.list[props.index],
                  description: e.target.value,
                })
              );
            }}
            value={lineas?.list[props.index]?.description}
            modified={!!lineas.list[props.index]?.validationModifications?.datos?.description}
          />
        </Grid>

        <Grid item xs={6} md={2}>
          <label>Cantidad</label>
          <EsfirusTextInput
            type="number"
            value={lineas?.list[props.index]?.amount}
            InputProps={{ inputProps: { min: 1 } }}
            fullWidth
            disabled={!props.editable}
            onChange={(e: any) => {
              const newCantidad = e.target.value

              const totalPrice = Number(lineas.list[props.index].precio) * Number(newCantidad);

              dispatch(
                updateLine({
                  ...lineas.list[props.index],
                  amount: newCantidad,
                  totalPrice,
                })
              );
            }}
            modified={!!lineas.list[props.index]?.validationModifications?.datos?.amount}
          />
        </Grid>

        {showPrice && (
          <Grid item xs={6} md={2}>
            <label>Precio</label>
            <EsfirusTextInput
              type="number"
              fullWidth
              value={lineas?.list[props.index]?.precio}
              disabled={!showPrice || !props.editable}
              onChange={(e: any) => {

                const totalPrice = Number(e.target.value ?? 0) * Number(lineas.list[props.index].amount ?? 0)

                dispatch(
                  updateLine({
                    ...lineas.list[props.index],
                    precio: e.target.value,
                    totalPrice
                  })
                );
              }}
              modified={!!lineas.list[props.index]?.validationModifications?.datos?.precio}
            />
          </Grid>
        )}
        {/* )} */}
      </Grid>
    </Grid>
  );
}

export default LineasGasto;
