import './header.scss'
import EsfirusSelect from '@ui/Select/EsfirusSelect';
import EsfirusSearch from '@ui/Search/EsfirusSearch';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTypeHour, setFilterBySearch, setSelectedEmpresa, setSelectedMesAnio } from '@store/slices/reports';
import { configurationService } from '@services/configApp';
import { isAdmin } from '@services/hooks/UserHook';
import { useEffect } from 'react';


function ReportHeader(props: any) {
    const dispatch = useDispatch();
    const empresas = useSelector((state) => (state as any).empresas).list;
    const tipoHora = useSelector((state) => (state as any).tipoHora).list;


    
    let _rangoInicio = new Date();
    _rangoInicio.setDate(1);

    let mes_anio = [];


    const { reportMesesAdmin, reportMeses, filterAllHours } = configurationService.getConfigReport();
    const configsMes = isAdmin() ? reportMesesAdmin : reportMeses;
    const fechaLimite = new Date(_rangoInicio.getFullYear(), _rangoInicio.getMonth() - configsMes, 1)
    while (_rangoInicio.getTime() > fechaLimite.getTime()) {
        const i = _rangoInicio.getMonth();
        const month = _rangoInicio.toLocaleString('default', { month: 'long' });
        const year = _rangoInicio.getFullYear().toString().substr(-2)
        mes_anio.push({
            mes: i + 1,
            label: `${month}-${year}`,
            anio: _rangoInicio.getFullYear()
        });
        _rangoInicio.setMonth(i - 1);
    }

  

    return (
        <div className='ef-pates-subheader'>
            <div className="ef-left">

                {props.admin && <EsfirusSelect placeholder='Seleccionar Empresa'
                    options={empresas.map((e: any) => ({ label: e.Nombre, value: e.Empresa }))}                    
                    value={props.selectedEmpresa}
                    change={(e: any) => {
                        dispatch(setSelectedEmpresa(e !== '' ? empresas.find((_e: any) => _e.Empresa.toString() === e.toString())?.Empresa : 0))
                    }}
                />}

                <EsfirusSelect
                    placeholder='Seleccionar mes'
                    options={
                        mes_anio.map((e: any) => ({ label: e.label, value: `${e.mes}-${e.anio}` }))
                    }
                    value={props.selectedMesAnio}
                    change={(e: any) => dispatch(setSelectedMesAnio(e))}
                />

                {filterAllHours && <EsfirusSelect
                    placeholder='Todas las horas'
                    value={props.selectedTypeHour}
                    options={tipoHora.map((th: any) => ({ label: th.NombreTipoHora, value: th.TipoHora }))}
                    change={(e: any) => dispatch(setSelectedTypeHour(e))}
                />}
                
                {props.admin && <EsfirusSearch change={
                    (e: any) => dispatch(setFilterBySearch(e as never))
                } placeholder="Buscar" />
                }
            </div>

            {/* <div className="ef-right">
                <EsfirusSelect placeholder='Todos 120' fill={true} options={[{ label: '1', value: '1' }, { label: '2', value: '2' }, { label: '3', value: '3' }]} />
                <div className="pagination">
                    <span></span>
                    <span></span>
                </div>
            </div> */}

        </div>
    );
}

export default ReportHeader;