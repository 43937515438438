export const calculateSeconds = (time) => {
  return time[0] * 3600 + time[1] * 60;
};

export const calculateTime = (seconds) => {
  let hora = (seconds - (seconds % 3600)) / 3600;
  if (hora > 23) {
    hora = hora - 24;
  }

  if (seconds % 3600) {
    const minutos = (seconds % 3600) / 60;
    return getFormatedDate(`${hora}:${minutos}`);
  }

  return getFormatedDate(`${hora}:00`);
};

const getFormatedDate = (date) => {
  return date
    .split(":")
    .map((el) => {
      return String(el).length === 1 ? `0${el}` : el;
    })
    .join(":");
};

export const getDayObraString = (time) => {
  if (time) {
    const date = new Date(time)
    const isoDateTime = date.toLocaleDateString("sv"); //IISO LOCAL

    return isoDateTime.substring(0, 10)
  }
  return ''
}


//TODO: revisar que pasa con las fehcas en la madrugada, por el timezone
export const getDateUTC = (date) => {
  return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000))


}
