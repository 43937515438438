import { priceFormat } from '@helpers/utils';
import { isEmpleado } from '@services/hooks/UserHook';
import { useSelector } from 'react-redux';

import './EsfirusResume.scss'

const EsfirusResumeAsync = () => {
	const isUserEmpleado: boolean = isEmpleado()
	const resumeTotalItems = useSelector((state) => (state as any).partes).resumeTotalItems
	const resumeTotalHoras = useSelector((state) => (state as any).partes).resumeTotalHoras
	const resumeTotalImporte = useSelector((state) => (state as any).partes).resumeTotalImporte

	// Para los usuarios tipo empleado no se les muestra ningún totalizador.
	if (isUserEmpleado) {
		return null
	}

	// Constantes del render.
	const horasDisplay = resumeTotalHoras.toFixed(2)
	const importeDisplay = priceFormat(resumeTotalImporte)

	return (
		<div className='ef-resume'>
			<div>Total Partes: {resumeTotalItems}</div>
			<div>Horas: {horasDisplay}</div>
			<div>Importe: {importeDisplay}</div>
		</div>
	)
}

export default EsfirusResumeAsync