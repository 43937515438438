import "./EsfirusTable.scss";
import pencilIcon from "@assets/svgs/edit-pencil.svg";
import eyeIcon from "@assets/svgs/visibility.svg";
import { useEffect, useState } from "react";
import ModalParte from "@screens/Home/BBs/modalParte/ModalParte";
import { reportLinesService } from "@services/reportLines";
import { useDispatch, useSelector } from "react-redux";
import { addLine, resetMarkForRemove, updateLine } from "@store/slices/lines";
import { Capitulo, Presupuesto } from "@models/obra";
import { updateSelectedObra } from "@store/slices/selectedObra";

import { constants } from "@helpers/constants";

import { getUserId, isEmpleado } from "@services/hooks/UserHook";

import ModalConfirm from "@screens/Report/BBs/ModalConfirm/ModalConfirm";
import { getDayObraString } from "@services/hooks/TimeHook";
import { configurationService } from "@services/configApp";
import DeleteIcon from '@mui/icons-material/Delete';
import checkSvg from "@assets/svgs/check.svg";
import { partesService } from "@services/partes";

import { resetLines } from "@store/slices/lines";
import usePartesGet from "@services/hooks/usePartesGet";
import ColorLegend from "../Legend/ColorLegend";

export default function EsfirusTable(props: EsfirusTableProps) {
  const dispatch = useDispatch();


  const [dias, setDias] = useState<any>([]);

  const calendarios = useSelector((state) => (state as any).calendarios).list;
  const filterByType = useSelector((state) => (state as any).partes).filterByType;

  const getPartes = usePartesGet()

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [dataClosedDay, setDataClosedDay] = useState({
    closed: 1,
    empleado: 0,
    day: ''
  });

  const colors = {
    white: {
      color: 'white',
      label: 'Pendiente'
    },
    gray: {
      color: 'gray',
      label: 'Confirmado'
    },
    green: {
      color: 'green',
      label: 'Validado'
    }


  }

  const { reportCerrarDias } = configurationService.getConfigReport();

  const [sorting, setSorting] = useState({ field: 'id', ascending: false })
  const [rows, setRows] = useState<any>([]);

  const applySorting = (field: any, ascending: any) => {

    const key = field.orderKeymap ?? field.keymap;
    setSorting({ field: field.keymap, ascending })
    const currentRowsCopy = [...rows ?? []];
    const reverseMultipe = ascending ? 1 : -1;

    function compare(a: any, b: any) {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    }

    const sortedCurrentRows = currentRowsCopy.sort((a, b) => {
      const sorted = key === 'date'
        ? new Date(a[key]).getTime() - new Date(b[key]).getTime()
        : compare(a, b)
      return sorted * reverseMultipe
    });
    setRows(sortedCurrentRows);

  }

  useEffect(() => {
    let dias: any = [];


    const splitSelectedDate = props?.selectedDate?.split('-');
    const year = splitSelectedDate[1]
    const month = splitSelectedDate[0]

    const config = localStorage?.getItem("ef-configuration") || "";
    const config_JSON = JSON.parse(config);
    const config_calendarios = config_JSON?.calendarios ?? {};
    let diasFestivos = calendarios
      .filter((c: any) => c?.Codigo === config_calendarios.calendario_defecto
        && c?.L && c?.M && c?.X && c?.J && c?.V && c?.S && c?.D)
      .map((c: any) => {
        const exploded = c?.FechaInicio?.split('-');
        return {
          day: Number(exploded[2]),
          month: Number(exploded[1]),
          year: Number(exploded[0])
        }
      })
      .filter((c: any) => c?.month === Number(month) && c?.year === Number(year))



    const date = new Date(year, month - 1, 1);

    let firstDay = date.getDay()
    const days = ['D', 'L', 'M', 'X', 'J', 'V', 'S']
    const isWeekend = (i: number) => (i + firstDay) % 7 === 6 || (i + firstDay) % 7 === 0 || diasFestivos.find((d: any) => d.day === i + 1);
    const dayName = (i: number) => `${days[(i + firstDay) % 7]}
    `;
    for (let i = 0; i < 31; i++) dias.push({ day: i + 1, name: dayName(i), color: isWeekend(i) ? "#8b8b8b" : "#26b7d4" });
    setDias(dias);
  }, [props?.selectedDate]);

  useEffect(() => {
    setRows(props?.rows)
  }, [props?.rows])





  const confirmClosed = (row: any, col: any, selectedDate: any) => {
    if (!reportCerrarDias) return;
    const hoy = new Date();
    const splitSelectedDate = selectedDate?.split('-');
    const year = Number(splitSelectedDate[1]) ?? hoy.getFullYear();
    const month = (Number(splitSelectedDate[0]) - 1) ?? hoy.getMonth();
    const day = Number(col.keymap)

    const _dateFormatString = new Date(year, month, day);


    if (!row['closedDays']?.includes(Number(col.keymap))) {
      setDataClosedDay({
        empleado: Number(row['id_empleado']),
        day: getDayObraString(_dateFormatString),
        closed: 1
      })
      setOpenConfirmModal(true);
    } else if (!isEmpleado()) {
      setDataClosedDay({
        empleado: Number(row['id_empleado']),
        day: getDayObraString(_dateFormatString),
        closed: 0
      })
      setOpenConfirmModal(true);
    }

  };


  /* modal Close day */
  const textHeaderCloseDay = (
    <div>
      <img src={checkSvg} className="check" alt="check" />
      {dataClosedDay?.closed === 1 ? '¿Està seguro de cerrar el dìa ?' : '¿Està seguro de abrir el dìa ?'}
    </div>
  )

  const onClickCloseDay = () => {
    dataClosedDay?.closed === 1 ? confirmClosedDay() : confirmOpenDay()
  }


  const confirmClosedDay = () => {

    partesService
      .closedDayPost(dataClosedDay)
      .then((res) => {
        setOpenConfirmModal(false);
        refreshDataGrid();
      })
      .catch((err) => console.error(err));

  }

  const confirmOpenDay = () => {

    partesService
      .closedDayPost(dataClosedDay)
      .then((res) => {
        setOpenConfirmModal(false);
        refreshDataGrid();
      })
      .catch((err) => console.error(err));

  }

  const refreshDataGrid = () => {
    dispatch(getPartes(0, 50, filterByType) as any);
    dispatch(resetLines());
  }
  /*FIN  modal Close day */

  const getDayBackColor = (localProps: any) => {
    const { row, col } = localProps;
    const isClosed = row['closedDays']?.includes(Number(col.keymap))

    const color =
      isClosed
        ? row[col.keymap].allConfirmed
          ? colors.green.color : colors.gray.color
        : colors.white.color

    return color

  }


  if (!props?.columns || !rows || !props.columns.length) {
    return <div className="ef-table"></div>;
  }
  return (
    <>
      <div className="ef-table">
        {!props.disableOnClickCell && <ColorLegend elements={[colors.white, colors.gray, colors.green]} />}
        <table className="ef-phone-fixed">
          <thead>
            <tr>
              {props.onCheckRow ?
                <div className="sticky-col head-col checkcolumn">
                  <th>{props.onCheckAll && <label className="containerCheckbox">
                    <input type="checkbox"
                      checked={props.seletedAll}
                      onChange={() => (props.onCheckAll as any)()} />
                    <span className="checkmark"></span>
                  </label>}</th>
                </div>
                : <></>}

              <th>
                <div className="sticky-col head-col">
                  {props.columns[0].name}
                  <button></button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows?.map((row: any, index: Number) => (
              <tr key={`tbody - td - ${index} `}>
                {props.onCheckRow ? <td >
                  <label className="containerCheckbox">
                    <input type="checkbox" checked={
                      props.pendingSelected[row.id]
                    } onChange={
                      (e) => {
                        (props.onCheckRow as any)({ row: row.id, value: !props.pendingSelected[row.id] });
                      }
                    } />
                    <span className="checkmark"></span>
                  </label>
                </td> : <></>}

              </tr>
            ))}
          </tbody>
        </table>

        <table className={props.onCheckRow ? "ef-desktop pending" : "ef-desktop"}>
          <thead>
            <tr>
              {props.onCheckRow ?
                <th className="mobile-hidde">{props.onCheckAll && <label className="containerCheckbox">
                  <input type="checkbox"
                    checked={props.seletedAll}
                    onChange={() => (props.onCheckAll as any)()} />
                  <span className="checkmark"></span>
                </label>}</th>
                : <></>}
              {props.columns?.map((col, index) => {
                const dia = dias.find((d: any) => d.day === Number(col.keymap));

                return (
                  <th
                    key={`desk - thead - th - ${index} `}
                    onClick={() => applySorting(col, !sorting?.ascending)}
                    style={{ backgroundColor: dia?.color ?? '', color: dia?.color ? 'white' : '' }}
                  >
                    <div
                      className="head-col"
                    >
                      {dia?.name ? <div className="text">{dia?.name}</div> : <></>}
                      {col.name}
                      {col?.order ? <button className={sorting?.field === col.keymap ? sorting?.ascending ? "up" : "down" : "none"}></button> : <></>}
                    </div>
                  </th>
                )
              }
              )}
            </tr>
          </thead>
          <tbody>
            {rows?.map((row: any, index: Number) => (
              <tr key={`rowid${row.id} -${index} `}>
                {props.onCheckRow ? <td className="mobile-hidde">
                  <label className="containerCheckbox">
                    <input type="checkbox" checked={
                      props.pendingSelected[row.id]
                    } onChange={
                      (e) => {
                        (props.onCheckRow as any)({ row: row.id, value: !props.pendingSelected[row.id] });
                      }
                    } />
                    <span className="checkmark"></span>
                  </label>
                </td> : <></>}
                {props.columns?.map((col, index) => {
                  const dia = dias.find((d: any) => d.day === Number(col.keymap));
                  if (dia) {
                    return (
                      <td
                        key={`desk - thead - th - text - ${index} `}
                        style={{ backgroundColor: getDayBackColor({ row, col }) }}
                        onClick={props.disableOnClickCell ? () => { } : () => confirmClosed(row, col, props?.selectedDate)}
                      >
                        <div
                          className="text"

                        >
                          {col.parse
                            ? col.parse(row[col.keymap]?.horas, row)
                            : row[col.keymap]?.horas}
                        </div>
                      </td>
                    );
                  }
                  return (
                    <td key={`desk - thead - th - text - ${index} `}>
                      <div className="text">
                        {col.parse
                          ? col.parse(row[col.keymap], row)
                          : row[col.keymap]}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}

          </tbody>
        </table>
      </div>


      <ModalConfirm open={openConfirmModal} setOpen={setOpenConfirmModal} textHeader={textHeaderCloseDay} onClick={onClickCloseDay} />

    </>
  );
}

export interface EsfirusTablePropsOption {
  label: string;
  value: any;
}
export interface EsfirusTableProps {
  columns?: { name: string; keymap: string; parse?: any; see?: boolean, order?: boolean, orderKeymap?: string }[];
  rows?: any[];
  editableRows?: number[];
  supervisor?: boolean;
  validating?: boolean;
  onCheckRow?: Function;
  onCheckAll?: Function;
  onOpenEdit?: Function;
  deleteReport?: Function;
  pendingSelected?: any;
  seletedAll?: boolean;
  selectedDate?: any;
  disableOnClickCell?: boolean;
}
