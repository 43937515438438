import './ColorLegend.scss';

const ColorLegend = (props: ColorLegendArray) => {
    return (
        <div className="legend">
            <dl>
                {
                    props.elements.map((element: ColorLegendElement, index: number) => {
                        return (
                            <>
                                <dt style={{ backgroundColor: element.color }}></dt>
                                <dd>{element.label}</dd>
                            </>
                        )
                    })
                }
            </dl>
        </div>
    );
}

export default ColorLegend;

export interface ColorLegendArray {
    elements: Array<ColorLegendElement>;
}

export interface ColorLegendElement {
    color: string;
    label: string;
}
