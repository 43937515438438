import "./EsfirusTable.scss";
import pencilIcon from "@assets/svgs/edit-pencil.svg";
import eyeIcon from "@assets/svgs/visibility.svg";
import { useEffect, useRef, useState } from "react";
import ModalParte from "@screens/Home/BBs/modalParte/ModalParte";
import { reportLinesService } from "@services/reportLines";
import { useDispatch, useSelector } from "react-redux";
import { addLine, resetMarkForRemove, updateLine } from "@store/slices/lines";
import { Capitulo, Presupuesto } from "@models/obra";
import { updateSelectedObra } from "@store/slices/selectedObra";

import { constants } from "@helpers/constants";

import { canSupervise, getUserId } from "@services/hooks/UserHook";

import ModalConfirm from "@screens/Report/BBs/ModalConfirm/ModalConfirm";
import { configurationService } from "@services/configApp";
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableViewIcon from '@mui/icons-material/TableView';

import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import useHandlerPaginationTable, { EsfirusTablePropsPagination, HandleChangePaginationType } from "@services/hooks/useHandlerPaginationTable";

export default function EsfirusTable(props: EsfirusTableProps) {
  const { customPageSizes = [50, 75, 100] } = props
  const dispatch = useDispatch();
  const tarifas = useSelector((state) => (state as any).articulosGastosServicios).list
  const manosDeObra = useSelector((state) => (state as any).mano_obra).list
  const presupuesto = useSelector((state) => (state as any).presupuestos).list;
  const capitulos = useSelector((state) => (state as any).capitulos).list;
  const selectedobra = useSelector((state) => (state as any).selectedobra).obra;
  const obrasFull = useSelector((state) => (state as any).obras).fullList;
  const usuario = getUserId();

  const tableRef = useRef<HTMLDivElement>(null)

  const [openSeeModal, setOpenSeeModal] = useState(false);
  const [dataParte, setDataParte] = useState<any>(null);
  const [readOnly, setReadOnly] = useState(false);
  const [toDelete, setToDelete] = useState(null);

  let lineas = useSelector((state) => (state as any).lines);

  const [openConfirmModalDelete, setOpenConfirmModalDelete] = useState(false);



  const [sorting, setSorting] = useState({ field: 'id', ascending: false })
  const [rows, setRows] = useState<any>([]);
  const totalWidth = useRef(0);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  // Gestión de la paginación con un hook.
  const totalItems = props.rowsCount ?? props.rows?.length ?? 0
  const { isFirstPage, isLastPage, intervalItems, sizePage, goBeforePage, goNextPage, handleChangePageSize } = useHandlerPaginationTable({ customPagination: props.pagination, totalItems, handleChangePagination: props.handleChangePagination })


  function getCurrentDimension() {
    let rect: any = {}
    if (tableRef && tableRef.current) {
      rect = tableRef.current.getBoundingClientRect();
    }
    const size = {
      width: rect?.width ?? window.innerWidth - 100,
      height: rect?.height ?? window.innerHeight
    }

    let width = props?.columns?.reduce((acc: number, el: any) => acc + (el?.width ?? 100), 0) ?? 0
    if (width > size.width) width = size.width;
    totalWidth.current = width;
    return size
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);


    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])

  const applySorting = (field: any, ascending: any) => {

    const key = field.orderKeymap ?? field.keymap;
    setSorting({ field: field.keymap, ascending })
    const currentRowsCopy = [...rows ?? []];
    const reverseMultipe = ascending ? 1 : -1;

    function compare(a: any, b: any) {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    }

    const sortedCurrentRows = currentRowsCopy.sort((a, b) => {
      const sorted = key === 'date'
        ? new Date(a[key]).getTime() - new Date(b[key]).getTime()
        : compare(a, b)
      return sorted * reverseMultipe
    });
    setRows(sortedCurrentRows);

  }



  useEffect(() => {
    setRows(props?.rows)
  }, [props?.rows])

  const setDataModal = async (id: string) => {
    if (rows) {
      dispatch(resetMarkForRemove());
      const data = rows?.find((el: any) => el._id === id);
      const extra = JSON.parse(data.extra || "{}")
      const recoveredObra = [...obrasFull].find((el) => el.Obra === data?.idObra && el.prefixId === extra?.prefixId);


      const allLines: any = await reportLinesService.getByParteId(data.id);
      const allLinesData = allLines.data.data.map((linea: any) => {
        return {
          ...JSON.parse(linea.datos || "{}"),
          idLinea: linea.id
        }
      });

      allLinesData.forEach((linea: any) => {
        dispatch(
          addLine({
            index: linea.index,
            type: linea.type,
          })
        );
        dispatch(
          updateLine({
            ...lineas.list[linea.index],
            ...linea,
          })
        );
      });

      const admin = data.tipo === "O" ? false : true;
      const obraParte = {
        Administracion: admin,
        blockedAdmin: admin,
        obraCode: data.idObra,
        name: data.nombreObra,
        tipo: data.tipo,
        date: data.date,
        validado: data.validado,
        extra,
        reference: data.referencia,
        nombre_empleado: data.nombre_empleado,
        responsible: data.responsable,
        company: recoveredObra?.Empresa,
        delegacion: recoveredObra?.Delegacion,
        codigoCliente: recoveredObra?.Cliente,
        // companyDestino: recoveredObra?.EmpresaDestino,
        tipo_obra: recoveredObra?.Tipo,
        calRef: recoveredObra?.Referencia,
        SolActividad: recoveredObra?.SolActividad,
        SolCapitulo: recoveredObra?.SolCapitulo,
        SolPresupuesto: recoveredObra?.SolPresupuesto,
        observaciones: extra?.observaciones,
      };

      setDataParte({
        ...data,
        lines: allLinesData,
      });

      // el.Obra == obra.obraCode && el.Empresa == obra.company && el.Delegacion == obra.delegacion
      const presupuestos: any = [...presupuesto]
        .filter((el) => el.Obra == obraParte.obraCode && el.Empresa == obraParte.company && el.Delegacion == obraParte.delegacion)
        .map((el: Presupuesto) => ({
          value: String(el?.Empresa + "_" + el?.Delegacion + "_" + el?.Presupuesto + "_" + el?.Anexo),
          label: String(el.Descripcion),
        }));

      const [idPresupuesto, anexoPresupuesto] = data.idPresupuesto ? data.idPresupuesto?.split("_") ?? [null, null] : [null, null];

      const selectedPresupuesto: any = [...presupuesto]
        .filter((el) => el.Presupuesto == idPresupuesto && el.Anexo == anexoPresupuesto && el.Obra == obraParte.obraCode && el.Empresa == obraParte.company && el.Delegacion == obraParte.delegacion)


      const capitulosByPresupuesto: Capitulo[] = [...capitulos]
        .filter((el) => el.Presupuesto == idPresupuesto && el.Anexo == anexoPresupuesto && el.Empresa == obraParte.company && el.Delegacion == obraParte.delegacion)
        .map((el: Capitulo) => ({
          ...el,
          disabled: !el?.Partida,
          ...formatCapituloLabel(el),
        })).sort((a, b) => Number(a.Capitulo) - Number(b.Capitulo));

      dispatch(
        updateSelectedObra({
          ...selectedobra,
          ...obraParte,
          presupuestos,
          selectedPresupuesto,
          capitulosByPresupuesto,
        })
      );
    }
  };


  const formatCapituloLabel = (capitulo: Capitulo) => {
    const finalcap: any = [];
    const fragmentos = capitulo.Capitulo.match(/.{1,3}/g);
    fragmentos?.forEach((el, k) => {
      const interestNumber = Number(el);
      const nextHaveNumber = Number(fragmentos.map((f, k2) => k2 > k ? f : "").join(""));
      if (interestNumber || nextHaveNumber) {
        finalcap.push(interestNumber);
      }
    });

    //insertar tabulador en prefix por cada elemento de finalcap
    let prefix = "";
    finalcap.forEach((el: any, k: any) => {
      prefix += k > 0 ? " - " : "";
    });
    const label = `cap ${finalcap.join(".")} ${capitulo.Descripcion}`;
    const labelPrefix = prefix;
    return { label, labelPrefix };
  };


  const returnTdImage = (
    validate: { icon: string; class: string },
    index: number,
    width: number,
    widthPercent: number,
    prevItem: any

  ) => {
    return (
      <div className="td"
        style={{ minWidth: width, width: `${widthPercent}%`, padding: 0 }}
        key={`desk-thead-th-image-${index}`}>
        {prevItem}
        <div className="image">
          {validate?.icon !== "" && (
            <img src={validate?.icon} className={validate?.class} alt="prueba" />
          )}
        </div>
      </div>
    );
  };

  const isEditable = (id: string): boolean => {
    if (rows) {
      const data = rows?.find((el: any) => el._id === id);
      const editableType = props?.editableRows?.length ? props?.editableRows : [constants.estadoParte.abierto, constants.estadoParte.rechazado];
      return editableType.includes(data.validado) && (props?.validating || data.usuario == usuario);
    }
    return false;
  };

  const isDeletable = (id: string): boolean => {
    const editable = isEditable(id);
    const data = rows?.find((el: any) => el._id === id);
    return editable && (data?.validado === constants.estadoParte.abierto || (data?.validado === constants.estadoParte.confirmado && canSupervise()));

  }


  const printTable = () => {
    //print screen to pdf
    window.scrollTo(0, document.body.scrollHeight);

    setTimeout(() => {
      window.print();
    }, 1000);

  }



  const exportCsv = () => {
    const data = rows?.map((row: any) => {
      let rowDef: any = {};
      columns?.forEach((col: any) => {
        let value = col.parse
          ? col.parse(row[col.keymap], row)
          : row[col.keymap]

        value = typeof value === 'object' ? JSON.stringify(value) : value
        rowDef[col.keymap] = value
      })
      return rowDef;
    });

    //export to csv
    let myHeaders = new Headers()
    // myHeaders.append(
    //   'Authorization',
    //   'Bearer cc995ebe4d893545fb6006854ec1cee3d1ffaac4'
    // )

    var formdata = new FormData()

    const dataPrint = {
      "columns": columns,
      "data": data

    }


    formdata.append('json', JSON.stringify(dataPrint))

    formdata.append(
      'ka_token',
      'cc995ebe4d893545fb6006854ec1cee3d1ffaac4'
    )

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
      // mode: 'no-cors',
      // credentials: 'omit',
      // referrerPolicy: 'no-referrer',
    }



    fetch(
      'https://esconnect.slowphilosophy.es/index.php/V1/file_gen/csv',
      requestOptions
    )
      .then(response => {
        // downloadFile(response.url, 'export.csv')
        return response.blob()
      })
      .then(blob => {
        console.log('Ok file', blob);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `export.csv`;
        link.click();
      })
      .catch(error => {
        console.log('error', error)
      });
  }



  /* Delete modal */
  const textHeaderDelete = "¿Estas seguro que quieres eliminar el parte?"
  const onClickDelete = () => {
    props?.deleteReport && toDelete && props.deleteReport(toDelete);
    setOpenConfirmModalDelete(false);

  }

  const nextRow = (currentWidth: number, lineWidth: number, displayWidth: number) => {
    return currentWidth + lineWidth > displayWidth;
  }

  /* FIN Delete modal */

  if (!props?.columns || !rows || !props.columns.length) {
    return <div className="ef-table"></div>;
  }
  let acumWidth = props.onCheckRow ? 50 : 0;
  const columns = props.columns?.map((col, index) => {
    const width = col.width ?? 100;
    acumWidth += width;
    const display = acumWidth > screenSize.width ? 'none' : 'inline-block';
    return {
      ...col,
      displayHead: display
    }
  })
  acumWidth = props.onCheckRow ? 50 : 0;

  totalWidth.current = acumWidth + columns?.reduce((acc: number, el: any) => acc + (el?.displayHead != 'none' ? (el?.width ?? 100) : 0), 0) ?? 0

  return (
    <>
      <div className="ef-table">

        <div
          ref={tableRef}
          className={(props.onCheckRow ? "ef-desktop pending" : "ef-desktop") + " table"}>
          <div className="thead">
            <div className="tr">
              {props.onCheckRow ?
                <div
                  className={"th"}
                  style={{ minWidth: 50, width: `${(50 * 100) / totalWidth.current}%` }}>
                  {props.onCheckAll && <label className="containerCheckbox">
                    <input type="checkbox"
                      checked={props.seletedAll}
                      onChange={() => (props.onCheckAll as any)()} />
                    <span className="checkmark"></span>
                  </label>}
                </div>
                : <></>}
              {columns?.map((col, index, row) => {
                const width = col.width ?? 100;
                const widthPercent = (width * 100) / totalWidth.current;
                acumWidth += width;
                const last = (index + 1 === row.length)

                // const newLine = nextRow(acumWidth, width, screenSize.width);
                return (
                  <div
                    className={"th"}
                    key={`desk-thead-th-${index}`}
                    onClick={() => applySorting(col, !sorting?.ascending)}
                    style={{ minWidth: width, width: `${widthPercent}%`, display: col.displayHead }}

                  >
                    <div className="head-col">
                      {col.name}

                      {col?.order ? <button className={"noPrint " + (sorting?.field === col.keymap ? sorting?.ascending ? "up" : "down" : "none")}></button> : <></>}
                      {last && props?.download && (
                        <div className="download">
                          {props?.download?.excel && <button onClick={exportCsv} className="download-csv noPrint"><TableViewIcon /></button>}
                          {props?.download?.pdf && <button onClick={printTable} className="download-pdf noPrint"><PictureAsPdfIcon /></button>}
                        </div>
                      )
                      }
                    </div>
                  </div>
                )
              }
              )}
            </div>
          </div>
          <div className="tbody">
            {rows?.map((row: any, index: Number) => {
              let acumWidth = props.onCheckRow ? 50 : 0;
              let someNewLine = false;
              return (

                <div
                  className="tr"
                  key={`rowid${row.id}-${index}`}>
                  {props.onCheckRow ?
                    <div
                      className="td"
                      style={{ minWidth: 50, width: `${(50 * 100) / totalWidth.current}%` }}
                    >
                      <label className="containerCheckbox">
                        <input type="checkbox" checked={
                          props.pendingSelected[row.id]
                        } onChange={
                          (e) => {
                            (props.onCheckRow as any)({ row: row.id, value: !props.pendingSelected[row.id] });
                          }
                        } />
                        <span className="checkmark"></span>
                      </label>
                    </div> : <></>}
                  {columns?.map((col, index) => {

                    let width = col.width ?? 100;
                    const widthPercent = (width * 100) / totalWidth.current;
                    const newLine = nextRow(acumWidth, width, screenSize.width);
                    let prevItem = <></>;
                    if (newLine) {
                      someNewLine = true;
                      acumWidth = 0
                    }
                    if (someNewLine) {
                      prevItem = <b>{col.name}: </b>
                      width *= 2
                    }
                    acumWidth += width;

                    if (col.keymap === "validadoImg") {
                      return returnTdImage(row[col.keymap], index, width, widthPercent, prevItem)

                    }


                    if (col.keymap === "_id" && col.see) {
                      return (
                        <div
                          className="td"
                          style={{ minWidth: width, width: `${widthPercent}%` }}
                          key={`desk-thead-container-${index}`}
                        >
                          <div className="text-image">
                            {prevItem}
                            {col.parse
                              ? col.parse(row['_id'])
                              : row['_id']}
                            <div className="extra-options">
                              <img
                                src={eyeIcon}
                                alt="visibility"
                                srcSet=""
                                className="filter-blue"
                                onClick={() => {
                                  if ((props as any).onOpenEdit) {
                                    (props as any).onOpenEdit(row)
                                  }
                                  setReadOnly(true);
                                  setDataModal(row['_id']);
                                  setOpenSeeModal(true);
                                }}
                              />
                              <img
                                src={pencilIcon}
                                alt="editable"
                                srcSet=""
                                className={
                                  isEditable(row['_id'])
                                    ? "filter-blue"
                                    : "filter-gray"
                                }
                                onClick={() => {
                                  if (isEditable(row['_id'])) {
                                    if ((props as any).onOpenEdit) {
                                      (props as any).onOpenEdit(row)
                                    }
                                    setReadOnly(false);
                                    setDataModal(row['_id']);
                                    setOpenSeeModal(true);
                                  }
                                }}
                              />
                              {props?.deleteReport && <DeleteIcon
                                className={
                                  isDeletable(row['_id'])
                                    ? "filter-blue"
                                    : "filter-gray"
                                } onClick={() => {
                                  if (isDeletable(row['_id'])) {
                                    setToDelete(row.id);
                                    setOpenConfirmModalDelete(true);
                                  }
                                }} />
                              }
                            </div>
                          </div>
                        </div>
                      );
                    }
                    if (col.keymap === "gpsIn" || col.keymap === "gpsOut") {
                      if (row[col.keymap]) {
                        const rParsed = col.parse(row[col.keymap], row)
                        return (
                          <div
                            className="td"
                            key={`desk-thead-th-text-${index}`}
                            style={{ minWidth: width, width: `${widthPercent}%` }}
                          >
                            {prevItem}
                            <a href={`https://maps.google.com/maps?q=${rParsed.lat},${rParsed.long}`} target="_blank">
                              <div style={{ display: "flex", flexDirection: "column", fontSize: "14px", padding: "2px" }}>
                                <span>Lat: {rParsed.lat}</span>
                                <span>Long: {rParsed.long}</span>
                              </div>
                            </a>
                          </div>
                        )
                      } else {
                        return <div
                          className="td"
                          key={`desk-thead-th-text-${index}`}
                          style={{ minWidth: width, width: `${widthPercent}%` }}
                        >
                          {prevItem}
                          <span>No hay ubicación GPS registrada</span>
                        </div>
                      }
                    }

                    if (width > screenSize.width) width = screenSize.width;

                    return (
                      <div
                        className="td"
                        key={`desk-thead-th-text-${index}`}
                        style={{ minWidth: width, width: `${widthPercent}%` }}

                      >

                        <div className="text">
                          {prevItem}

                          {col.parse
                            ? col.parse(row[col.keymap], row)
                            : row[col.keymap]}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )
            }
            )}

          </div>
          {props.withPagination && (
            <div className="tfoot">
              <div className="tr">
                <div className="td">
                  <span>Filas por página</span>
                  <select className="select-size" value={sizePage} onChange={handleChangePageSize}>
                    {customPageSizes.map(size => <option>{size}</option>)}
                  </select>
                </div>
                <span>{intervalItems.initial}-{intervalItems.final} de {totalItems}</span>
                <div className="td">
                  <IconButton disabled={isFirstPage} onClick={goBeforePage}>
                    <NavigateBefore sx={{ color: isFirstPage ? "gray" : "#075577" }} />
                  </IconButton>
                  <IconButton disabled={isLastPage} onClick={goNextPage}>
                    <NavigateNext sx={{ color: isLastPage ? "gray" : "#075577" }} />
                  </IconButton>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {openSeeModal && dataParte && (
        <ModalParte
          data={dataParte}
          open={openSeeModal}
          setOpen={(open: any) => {
            if (!open) {
              setDataParte(null)
            }
            setOpenSeeModal(open)
          }}
          readOnly={readOnly}
          validating={props.validating ?? false}
        />
      )}

      <ModalConfirm className={"rejected"} open={openConfirmModalDelete} setOpen={setOpenConfirmModalDelete} textHeader={textHeaderDelete} onClick={onClickDelete} />

    </>
  );
}

export interface EsfirusTablePropsOption {
  label: string;
  value: any;
}

export interface EsfirusTablePropsDownload {
  pdf: boolean;
  excel: boolean;
}

export interface EsfirusTableProps {
  columns?: { name: string; keymap: string; parse?: any; see?: boolean, order?: boolean, orderKeymap?: string, width?: number }[];
  rows?: any[];
  rowsCount?: number;
  editableRows?: number[];
  supervisor?: boolean;
  validating?: boolean;
  onCheckRow?: Function;
  onCheckAll?: Function;
  onOpenEdit?: Function;
  deleteReport?: Function;
  pendingSelected?: any;
  seletedAll?: boolean;
  selectedDate?: any;
  download?: EsfirusTablePropsDownload;
  pagination?: EsfirusTablePropsPagination;
  withPagination?: boolean;
  customPageSizes?: number[];
  handleChangePagination?: HandleChangePaginationType;
}
