import EsfirusIcon from "@components/ui/icons/EsfirusIcon";
import EsfirusSelect from "@components/ui/Select/EsfirusSelect";
import EsfirusTextInput from "@components/ui/Text-input/EsfirusTextInput";
import { Obra, Capitulo, Presupuesto } from "@models/obra";
import { Grid, InputAdornment } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import 'dayjs/locale/es';

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ModalNewObra from "@screens/NewParte/BBs/ModalNewObra/ModalNewObra";
import {
  removeSelectedObra,
  updateSelectedObra,
} from "@store/slices/selectedObra";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import zoomIcon from "@assets/images/icons/zoomIcon.png";
import { configurationService } from "@services/configApp";
import { getDateUTC, getDayObraString } from "@services/hooks/TimeHook";
import { getUserId } from "@services/hooks/UserHook"
import moment from "moment";

export function HeaderFormParte(props: any) {

  const dispatch = useDispatch();
  const { presupuesto, capitulos, selectedobra, lineas, setDateInvalid, closedDays, isCreation } = props;
  const [openObraModal, setOpenObraModal] = useState(false);
  const { editTipoparte, parteShowRefParte, autoRefParte, showTipoParte, partesObservaciones } =
    configurationService.getConfigCabeceraParte();

  const tarifas = useSelector((state) => (state as any).articulosGastosServicios).list
  const manosDeObra = useSelector((state) => (state as any).mano_obra).list

  useEffect(() => {
    //solo controlamos los partes que estan en estado 0, los demas se podran modificar (por si esta cerrado pero permite modificarlo an rechazar)
    if (!selectedobra?.validado) {
      const dayObra = getDayObraString(selectedobra?.date)

      const userId = getUserId();
      const closed = closedDays?.filter((closed: any) => closed?.empleado === userId)?.filter((closed: any) => {
        const _daySplit = closed?.day?.split('-');
        if (_daySplit?.lenght < 2) return false;
        return true;
      })?.map(
        (closed: any) => {
          const _daySplit = closed?.day?.split('-');
          if (_daySplit?.lenght < 2) return false;
          return `${_daySplit[0]}-${_daySplit[1]}-${_daySplit[2]}`;
        }
      )
        .filter((value: any, index: any, array: any) => array.indexOf(value) === index);

      const invalid = selectedobra?.date && selectedobra?.date > new Date().getTime() ? 2 : closed?.includes(dayObra) ? 1 : 0;
      setDateInvalid(invalid);

    }
    if (!selectedobra?.date && !isNaN(selectedobra?.date)) {
      dispatch(
        updateSelectedObra({
          ...selectedobra,
          date: new Date().getTime(),
        }
        ))
    }
  }, [selectedobra?.date])

  const selectObra = async (obra: Obra) => {
    const { Administracion, obraCode } = obra;

    let capitulosByPresupuesto: Capitulo[] = [];
    let selectedPresupuesto = [];
    dispatch(removeSelectedObra());
    const presupuestosFiltered = getPresupuestosByObra(obra).map(
      (el: Presupuesto) => ({
        value: String(el.Empresa + "_" + el.Delegacion + "_" + el.Presupuesto + "_" + el.Anexo),
        label: String(el.Presupuesto + " - " + el.Descripcion),
      })
    );

    if (presupuestosFiltered.length === 1) {
      const _obj = getCapitulosByPresupuesto(presupuestosFiltered[0].value);
      capitulosByPresupuesto = _obj.capitulosByPresupuesto;
      selectedPresupuesto = _obj.selectedPresupuesto;
    }
    else if (presupuestosFiltered.length > 1) {
      const presupuestosIds = presupuestosFiltered.map((p: any) => p.value);
      const _capitulos: Capitulo[] = [...capitulos].filter(
        (el) => presupuestosIds.includes(el.Presupuesto.toString())
      );

      capitulosByPresupuesto = _capitulos.map((c: any) => ({
        ...c,
        ...formatCapituloLabel(c),
      }))
    }

    obra.tipo = Administracion ? 'A' : 'O';
    const date = new Date()
    dispatch(
      updateSelectedObra({
        ...selectedobra,
        ...obra,
        date: date.getTime(),
        presupuestos: presupuestosFiltered,
        selectedPresupuesto,
        capitulosByPresupuesto,
        blockedAdmin: Administracion,
      })
    );
  };

  const getPresupuestosByObra = (obra: any): Presupuesto[] => {
    return [...presupuesto].filter((el) => el.Obra == obra.obraCode && el.Empresa == obra.company && el.Delegacion == obra.delegacion).sort((a: any, b: any) => moment(b.Fecha).valueOf() - moment(a.Fecha).valueOf());
  };

  const getCapitulosByPresupuesto = (id: string) => {
    const [Empresa, Delegacion, Presupuesto, Anexo] = id.split("_");

    const _capitulos: Capitulo[] = [...capitulos].filter(
      (el) => el.Presupuesto == Presupuesto && el.Empresa == Empresa && el.Delegacion == Delegacion && el.Anexo == Number(Anexo)
    );
    const selectedPresupuesto = [...presupuesto].filter(
      (el: Presupuesto) => el.Presupuesto === Number(Presupuesto) && el.Empresa == Number(Empresa) && el.Delegacion == Number(Delegacion) && el.Anexo == Number(Anexo)
    );


    const capitulosByPresupuesto: Capitulo[] = _capitulos?.map(
      (el: Capitulo) => ({
        ...el,
        disabled: !el?.Partida,
        ...formatCapituloLabel(el),
      })
    ).sort((a, b) => Number(a.Capitulo) - Number(b.Capitulo));
    return {
      selectedPresupuesto,
      capitulosByPresupuesto,
    };
  };

  const formatCapituloLabel = (capitulo: Capitulo) => {
    const finalcap: any = [];
    const fragmentos = capitulo.Capitulo.match(/.{1,3}/g);
    fragmentos?.forEach((el, k) => {
      const interestNumber = Number(el);
      const nextHaveNumber = Number(fragmentos.map((f, k2) => k2 > k ? f : "").join(""));
      if (interestNumber || nextHaveNumber) {
        finalcap.push(interestNumber);
      }
    });

    //insertar tabulador en prefix por cada elemento de finalcap
    let prefix = "";
    finalcap.forEach((el: any, k: any) => {
      prefix += k > 0 ? " - " : "";
    });

    const label = `cap ${finalcap.join(".")} ${capitulo.Descripcion}`;
    const labelPrefix = prefix;
    return { label, labelPrefix };
  };

  const getPresupuestoValue = (): any => {
    if (
      selectedobra?.selectedPresupuesto &&
      selectedobra?.selectedPresupuesto.length === 1
    ) {
      const el = selectedobra?.selectedPresupuesto[0]
      return el?.Empresa + "_" + el?.Delegacion + "_" + el?.Presupuesto + "_" + el?.Anexo;
    }
    return "";
  };

  const getStatePresupuesto = () => {
    if (!props.editable) {
      return true;
    }

    if (selectedobra?.presupuestos?.length === 1 || lineas.length !== 0) {
      return true;
    }

    return false;
  };

  const getTypeObraDisabled = () => {
    if (!props.editable || !editTipoparte || lineas.length !== 0) {
      return true;
    }
    return selectedobra.blockedAdmin;
  };

  const typeObraVisible = showTipoParte

  return (
    <>
      <Grid container spacing={{ xs: 1, md: 4 }} >
        <Grid item xs={12} sm={6} md={3}>
          <label>Empresa</label>
          <EsfirusTextInput modified={!!selectedobra.extra?.validationModifications?.company} disabled fullWidth value={selectedobra?.company} />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <label>Obra</label>
          <EsfirusTextInput
            fullWidth
            aria-readonly
            value={selectedobra?.description || selectedobra?.name}
            size="small"
            inputProps={{ readOnly: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  className="pointer"
                  onClick={() => {
                    if (lineas.length === 0) {
                      setOpenObraModal(true);
                    }
                  }}
                >
                  <EsfirusIcon path={zoomIcon} />
                </InputAdornment>
              ),
            }}
            disabled={!props.editable || lineas.length !== 0}
          />
        </Grid>

        {typeObraVisible && <Grid item xs={12} sm={6} md={3}>
          <label>Tipo de parte</label>
          <EsfirusSelect
            options={[
              { value: "A", label: "Administracion" },
              { value: "O", label: "Obra" },
            ]}
            change={(e: any) => {
              const Administracion = e === "A" ? true : false;
              dispatch(
                updateSelectedObra({
                  ...selectedobra,
                  tipo: e,
                  Administracion,
                })
              );
            }}
            disabled={getTypeObraDisabled()}
            value={selectedobra.tipo}
            placeholder={"Tipo"}
            modified={!!selectedobra.extra?.validationModifications?.tipo}
          />
        </Grid>}

        {!selectedobra.Administracion && (
          <>
            {selectedobra.SolPresupuesto && (
              <Grid item xs={12} sm={6} md={3}>
                <label>Presupuesto</label>
                <EsfirusSelect
                  change={(e: string) => {
                    if (!selectedobra) {
                      return;
                    }

                    const { capitulosByPresupuesto, selectedPresupuesto } =
                      getCapitulosByPresupuesto(e);

                    dispatch(
                      updateSelectedObra({
                        ...selectedobra,
                        capitulosByPresupuesto,
                        selectedPresupuesto,
                      })
                    );
                  }}
                  key="selectorPresupuesto"
                  options={selectedobra?.presupuestos}
                  value={getPresupuestoValue()}
                  disabled={getStatePresupuesto()}
                  placeholder="Presupuesto"
                  modified={!!selectedobra.extra?.validationModifications?.selectedPresupuesto}
                  required
                />
              </Grid>
            )}
          </>
        )}

        <Grid item xs={12} sm={6} md={3}>
          <label>Empleado</label>
          <EsfirusTextInput
            fullWidth
            disabled
            value={isCreation ? (localStorage?.getItem("ef-user-name") ?? "") : (selectedobra?.nombre_empleado ?? "")}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <label>Fecha</label>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DatePicker
              value={selectedobra?.date}
              onChange={(newDate) => {
                const dateSelected = moment({ day: newDate.$D, month: newDate.$M, year: newDate.$y })
                dispatch(
                  updateSelectedObra({
                    ...selectedobra,
                    date: dateSelected.valueOf(),
                  })
                );
              }}

              renderInput={(params) => (
                <EsfirusTextInput modified={!!selectedobra.extra?.validationModifications?.date} fullWidth {...params} />
              )}
              disabled={!props.editable}
              inputFormat="DD/MM/YYYY"
            />
          </LocalizationProvider>
          <label style={{ color: 'red', display: props.dateInvalid ? '' : 'none', width: '100%', textAlign: 'center' }} > {props.dateInvalid == 1 ? "Dìa cerrado" : "No se pueden crear partes a futuro"}</label>
        </Grid>

        {parteShowRefParte && (
          <Grid item xs={12} sm={6} md={4}>
            <label>Referencia</label>
            <EsfirusTextInput
              fullWidth
              disabled={
                (!props.editable ||
                  lineas.length !== 0 ||
                  autoRefParte !== "")
              }
              value={selectedobra?.reference}
              onChange={(e: any) => {
                dispatch(
                  updateSelectedObra({
                    ...selectedobra,
                    reference: e.target.value,
                  })
                );
              }}
              modified={!!selectedobra.extra?.validationModifications?.reference}
            />
          </Grid>
        )}
        {partesObservaciones && (
          <Grid item xs={12} sm={12} md={12}>
            <label>Observaciones</label>
            <EsfirusTextInput
              fullWidth
              disabled={!props.editable}
              value={selectedobra?.observaciones}
              onChange={(e: any) => {
                dispatch(
                  updateSelectedObra({
                    ...selectedobra,
                    observaciones: e.target.value,
                  })
                );
              }}
              modified={!!selectedobra.extra?.validationModifications?.extra?.observaciones}
            />
          </Grid>
        )}
      </Grid>

      {
        openObraModal && <ModalNewObra
          open={openObraModal}
          setOpen={setOpenObraModal}
          selectObra={selectObra}
        />
      }
    </>
  );
}
