import { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";
import "./GeneralModalError.scss";

const GeneralModalError = () => {
  const [error, setError] = useState(false)

  const handlerError = (e: any) => {
    setError(true)
  }

  useEffect(() => {
    document.addEventListener("generalerror", handlerError)

    return () => {
      document.removeEventListener("generalerror", handlerError)
    }
  }, [])

  if (!error) return <></>

  return (
    <Modal
      open={error}
      onClose={() => setError(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="general-modal-error">
        <div className="header">
          <h5 className="text-center">
            Algo ha salido mal
          </h5>
        </div>
        <div className="body">
          <div className="text-center ph-30">Inténtelo más tarde y si persiste el error, póngase en contacto con su administrador.</div>
          <div className="button-container">
            <EsfirusButtonNative
              click={() => setError(false)}
              label="Aceptar"
            ></EsfirusButtonNative>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default GeneralModalError