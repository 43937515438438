import { createSlice } from "@reduxjs/toolkit";
import { tipoHoraService } from "@services/tipoHora";

const initial = {
  list: [],
};

export const ObraSlice = createSlice({
  name: "tipoHora",
  initialState: { ...initial },
  reducers: {
    resetTipoHora: (state, action: any) => {
      state.list = [...initial.list];
    },
    setHoras: (state, action: any) => {
      state.list = [...action.payload] as any;
    },
  },
});

export default ObraSlice.reducer;
export const { setHoras, resetTipoHora } = ObraSlice.actions;

