import { apiConfig } from "./config";
import { Configuration } from "./swagger/configuration";
import { ReportsApi } from "./swagger/apis/reports-api";
import { getExternalCompany, getInternalCompany } from "@helpers/companyData";
import { isAdmin } from "./hooks/UserHook";
import { reportLinesService } from "./reportLines";
import { getBase64FromCanvaDiv } from "@helpers/utils";
import axios from "axios";
import { constants } from "@helpers/constants";

class PartesService extends ReportsApi {
  accessToken = "";

  constructor(_apiConfig: Configuration) {
    super(_apiConfig);
  }

  get(months: number, page: number, pageSize: number, estado: number, filters: any) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    const timeDiff = new Date().getTimezoneOffset();

    try {
      const resp: any = this.reportsGet({ headers: { Authorization: accessToken }, params: { months, timeDiff, page, pageSize, estado, filters: JSON.stringify(filters ?? {}) } });
      return resp
    } catch (e) {

      return e
    }
  }

  getById(id: string) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    return this.reportIdGet(id, { headers: { Authorization: accessToken } });
  }

  post(data: any) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    return this.reportPost({
      headers: { Authorization: accessToken },
      data,
    });
  }

  put(data: any) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    return this.reportIdPut(data.id, {
      headers: { Authorization: accessToken },
      data,
    });
  }

  closedDayPost(data: any) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    return this.reportClosedDayPost({
      headers: { Authorization: accessToken },
      data,
    });
  }

  getClosedDay() {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    return this.reportsGetClosedDay({ headers: { Authorization: accessToken } });
  }

  async getPartesValidating() {
    try {
      const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
      const response = await axios({
        baseURL: this.basePath + '/reports/validating',
        headers: { Authorization: accessToken },
        method: 'get'
      })

      return response
    } catch (error) {
      apiConfig?.catchError(error)
    }
  }

  async getAllTotalItems(months: number, filters: any) {
    try {
      const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
      const timeDiff = new Date().getTimezoneOffset();

      const response = await axios({
        baseURL: this.basePath + '/reports/allTotalItems',
        headers: { Authorization: accessToken },
        method: 'get',
        params: { months, timeDiff, filters: JSON.stringify(filters ?? {}) }
      })

      return response
    } catch (error) {
      apiConfig?.catchError(error)
    }
  }

  async getResume(months: number, estado: number, filters: any) {
    try {
      const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
      const timeDiff = new Date().getTimezoneOffset();

      const response = await axios({
        baseURL: this.basePath + '/reports/resume',
        headers: { Authorization: accessToken },
        method: 'get',
        params: { months, timeDiff, estado, filters: JSON.stringify(filters ?? {}) }
      })

      return response
    } catch (error) {
      apiConfig?.catchError(error)
    }
  }

  parseObra(selectedobra: any, userId: any, lineas: any[], validado: number) {
    const date = new Date(selectedobra?.date);
    //dia mes año
    const dateLocal = date.toLocaleDateString("es-ES").replace(/\//g, "-");
    const presu = selectedobra?.selectedPresupuesto[0]
    return {
      id: selectedobra?.id ?? null,
      idObra: selectedobra?.obraCode,
      idObraExterna: selectedobra?.obraExternaCode,
      delegacion: selectedobra?.delegacion,
      nombreObra: selectedobra?.description,
      nombreEmpresa: selectedobra?.nombreCliente,
      tipo: selectedobra?.Administracion ? 'A' : 'O',
      referencia: selectedobra?.reference,
      calRef: selectedobra?.calRef,
      responsable: selectedobra?.responsible,
      idPresupuesto: presu ? presu?.Presupuesto + "_" + presu?.Anexo : false,
      SolCapitulo: selectedobra?.SolCapitulo,
      SolPresupuesto: selectedobra?.SolPresupuesto,
      date: selectedobra?.date,
      dateLocal,
      idCapitulo: 1,
      validado,
      nombreCapitulo: "",
      usuario: userId,
      empresa: selectedobra?.company || 0,
      prefixId: selectedobra?.prefixId || 0,
      empresaExterna: selectedobra?.companyDestino || 0,
      empleado: selectedobra?.empleado || 0,
      empleadoExterno: selectedobra?.empleadoExterno || 0,
      referenciaExterna: selectedobra?.referenciaExterna || "",
      observaciones: selectedobra?.observaciones || "",
      codigoCliente: selectedobra?.codigoCliente || "",
      admin: isAdmin(),
      lines: [
        ...lineas?.map((line: any) => reportLinesService.parseReportLine(line, date)),
      ],
    }
  }

  async saveReport(
    selectedobra: any,
    lineas: any,
    validado: number = 0,
    config?: any,
  ) {
    const userId = localStorage.getItem("ef-user-id");
    return this.post(this.parseObra(selectedobra, userId, lineas, validado))
  };

  async updateReport(
    selectedobra: any,
    lineas: any,
    validado: number = 0,
    config?: any,
  ) {

    const userId = localStorage.getItem("ef-user-id");
    const parsed = this.parseObra(selectedobra, userId, lineas, validado)
    return this.put(parsed)
  };

  async saveReportAndSign(
    selectedobra: any,
    lineas: any,
    validado: any,
    toSave: boolean
  ) {
    const dataURL = getBase64FromCanvaDiv("divCanva");
    const userId = localStorage.getItem("ef-user-id");

    const parsedObra = this.parseObra(selectedobra, userId, lineas, validado)
    return this.post(
      {
        ...parsedObra,
        firma: toSave ? dataURL : undefined,
      }

    )
  };

  confirmarParte(id: string) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    return this.reportIdValidatePut(id, {
      headers: { Authorization: accessToken },
      data: { validado: 2 },
    });
  }

  rechazarParte(id: string, msg: string) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    return this.reportIdValidatePut(id, {
      headers: { Authorization: accessToken },
      data: {
        validado: 3,
        extra: { msg }
      },
    });
  }
}

export const partesService = new PartesService(apiConfig);
