import { useEffect, useState } from "react"
import EsfirusSelect from "@components/ui/Select/EsfirusSelect"
import { getRequest, postRequest } from "@screens/Backoffice/utils/requests"
import moment from "moment"
import ModalConfirmar from "./ModalConfirmar"
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative"

const UpdateClientList = () => {
  // Carga de builds.
  const [loadingBuilds, setLoadingBuilds] = useState(true)
  const [builds, setBuilds] = useState([])

  // Carga de dockers.
  const [loadingDockers, setLoadingDockers] = useState(true)
  const [dockers, setDockers] = useState([])

  // Compilación seleccionada.
  const [buildSelected, setBuildSelected] = useState("")

  // Clientes seleccionados.
  const [clientsSelected, setClientsSelected] = useState([])

  // Estados de actualización de clientes.
  const [loadingUpdateClients, setLoadingUpdateClients] = useState(false)
  const [updateClientsFinished, setUpdateClientsFinished] = useState(false)
  const [updateClientsFailed, setUpdateClientsFailed] = useState(false)
  const [showModalUpdateClients, setShowModalUpdateClients] = useState(false)

  useEffect(() => {
    fetchBuilds()
    fetchDockers()
  }, [])

  const fetchBuilds = async () => {
    setLoadingBuilds(true)
    try {
      const compilacionesResponse = await getRequest("compilaciones")
      const compilaciones = compilacionesResponse.data
      setBuilds(compilaciones)
    } catch (error) {
      setBuilds([])
    } finally {
      setLoadingBuilds(false)
    }
  }

  const fetchDockers = async () => {
    setLoadingDockers(true)
    try {
      const clientesResponse = await getRequest('modules/sas/dockers/list')
      const clientes = clientesResponse.data
      setDockers(clientes)
    } catch (error) {
      setDockers([])
    } finally {
      setLoadingDockers(false)
    }
  }

  const updateClients = async () => {
    setLoadingUpdateClients(true)
    try {
      const updates = clientsSelected.map(client => postRequest('modules/sas/dockers/restart', { name: client, version: buildSelected }))
      const updatesResponse = await Promise.allSettled(updates)
      const someUpdateFailed = updatesResponse.some(update => update.status === "rejected")
      if (someUpdateFailed) throw "Failed"
    } catch (error) {
      setUpdateClientsFailed(true)
    } finally {
      await fetchDockers()
      setLoadingUpdateClients(false)
      setUpdateClientsFinished(true)
    }
  }

  return (
    <div className="update-clients-wrapper">
      <div>
        <div className="update-clients-section-title">Selecciona la compilación a setear:</div>
        <EsfirusSelect
          style={{ maxWidth: "350px" }}
          options={builds.map(build => {
            const label = `${build.hash} | ${moment(build.time).format("DD-MM-YYYY")}`
            return ({ value: build.hash, label })
          })}
          change={(e) => setBuildSelected(e)}
          disabled={loadingBuilds}
          value={buildSelected}
          placeholder="Seleccionar"
        />
      </div>
      <div>
        <div className="update-clients-section-title">Selecciona los clientes que se desee actualizar:</div>
        <div className="update-clients-client-box-wrapper">
          {loadingDockers ? (
            <span>Cargando clientes...</span>
          ) : (dockers.map(docker =>
            <div
              className={`update-clients-client-box ${clientsSelected.includes(docker.name) ? "selected" : ""}`}
              onClick={() => {
                const dockerName = docker.name
                setClientsSelected(prev => {
                  const isSelected = prev.includes(dockerName)
                  if (isSelected) return prev.filter(client => client !== dockerName)
                  else return [...prev, dockerName]
                })
              }}
            >
              <div className="update-clients-client-box-name">{docker.name}</div>
              <div className="update-clients-client-box-hash">{docker.hash}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="update-clients-buttons-wrapper">
        <EsfirusButtonNative
          click={() => {
            if (!buildSelected || buildSelected === "") {
              alert("Selecciona la compilación a introducir en los clientes.")
              return
            }

            if (clientsSelected.length === 0) {
              alert("Selecciona algún cliente para actualizar.")
              return
            }

            setShowModalUpdateClients(true)
          }}
          label="Actualizar clientes"
        />
      </div>

      {/* Modal de confirmación de creación de nueva build */}
      {showModalUpdateClients && (
        <ModalConfirmar
          title="Actualización de clientes"
          open={showModalUpdateClients}
          onClose={() => {
            setShowModalUpdateClients(false)
            setLoadingUpdateClients(false)
            setUpdateClientsFinished(false)
            setUpdateClientsFailed(false)
          }}
          initialText={`¿Estás seguro que quieres actualizar los clientes a la compilación ${buildSelected}?`}
          loading={loadingUpdateClients}
          msgLoading="Actualizando los clientes."
          finished={updateClientsFinished}
          msgFinished="Clientes actualizados correctamente."
          failed={updateClientsFailed}
          msgFailed="Algo ha salido mal en algún cliente, vuelve a intentarlo en unos minutos."
          action={async () => await updateClients()}
        />
      )}
    </div>
  )
}

export default UpdateClientList