import Modal from "@mui/material/Modal";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";
import { useMemo } from "react";

const ModalConfirmar = ({ title, open, onClose, initialText, loading, msgLoading, finished, msgFinished, failed, msgFailed, action }) => {
  const msg = useMemo(() => {
    if (failed) return msgFailed
    if (finished) return msgFinished
    if (loading) return msgLoading
    return initialText
  }, [initialText, loading, msgLoading, finished, msgFinished, failed, msgFailed])

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="new-confirm-modal-sign">
        <div className="header">
          <h5 className="text-center">
            {title}
          </h5>
        </div>
        <div className="body">
          {loading && <span className="loader" style={{ color: "#075577" }}></span>}
          <div className="text-center ph-30">
            {msg}
          </div>
          <div className="button-container">
            {!finished && (
              <EsfirusButtonNative
                click={action}
                disabled={loading}
                label="Sí"
              />
            )}
            <EsfirusButtonNative
              className={finished ? "" : "tertiary"}
              click={() => onClose()}
              disabled={loading}
              label={finished ? "Cerrar" : "No"}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalConfirmar;
