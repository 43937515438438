const propsToShow = [{
  key: "calendarios",
  label: "Calendarios",
}, {
  key: "capitulos",
  label: "Capitulos",
}, {
  key: "categorias",
  label: "Categorias",
}, {
  key: "empleados",
  label: "Empleados",
}, {
  key: "empresas",
  label: "Empresas",
}, {
  key: "mano_obra",
  label: "Manos de obra",
}, {
  key: "obras",
  label: "Obras",
}, {
  key: "presupuestos",
  label: "Presupuestos",
}, {
  key: "tarifas",
  label: "Tarifas",
}, {
  key: "tipos",
  label: "Tipos",
}]

const CacheInfo = ({ cache = {} }) => {
  return (
    <div className="cache-info-wrapper">
      <span className="cache-info-title">Datos del servidor:</span>
      <ul className="cache-info-list">
        {propsToShow.map(instance => {
          const noCharge = !cache[instance.key]
          const noItems = cache[instance.key] && !cache[instance.key].length
          const message = noCharge
            ? "No cargado en servidor."
            : noItems
              ? "No hay items de esta instancia."
              : `Hay ${cache[instance.key].length} elementos de esta instancia`

          return (
            <li style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <span style={{ fontWeight: "bold" }}>{instance.label}:</span>
              <span style={{ color: (noCharge || noItems) ? "red" : "green" }}>{message}</span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default CacheInfo